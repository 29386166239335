import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Layout/Seo"

import { HeroSimple } from "../components/Components/Heroes/HeroSimple"
import Navbar from "../components/Layout/Navbar"
import { LandingGrid } from "../components/Components/Content/LandingGrid"

const IndexPage = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [data])

  console.log(data)

  //const image = getImage(data.image.childImageSharp.gatsbyImageData)

  const projects = data.projects.nodes

  return (
    <Layout>
      <Seo
        title="Kinzica Studio | Your music, our craft"
        description="Kinzica Studio - Composition | Mix/Mastering | Sonic Branding | Sound Design | Music supervision"
        url="www.kinzicastudio.com"
      />

      <div className="w-full mx-auto">
        <HeroSimple id={"main-hero"} />
        <LandingGrid id={"some-works"} projects={projects} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query Projects {
    projects: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 6
    ) {
      nodes {
        frontmatter {
          slug
          title
          tag
          thumb {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        id
      }
    }
  }
`
