
import { GatsbyImage } from "gatsby-plugin-image";
import React from 'react';



export const Card = ({ firstImage, title }) => {

  return (
    <a href="/" aria-label="View Item">
      <div className="relative overflow-hidden transition duration-500 transform">
        <GatsbyImage
          className="object-cover w-full h-56 md:h-64 xl:h-80"
          image={firstImage}
          alt=""
        />
        <div className="flex justify-center 
            items-center absolute inset-0 px-6 py-4 
            transition-opacity duration-500 bg-kinzica-dark 
            lg:bg-opacity-75 lg:opacity-0 lg:hover:opacity-100
            bg-opacity-75
            ">
          <h1 className="text-4xl text-gray-100">{title}</h1>
        </div>
      </div>
    </a>
  )

}
