/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll';


export const HeroSimple = ({ id }) => {

	return (
		<section id={id} className="bg-gray-900">

			<div className="pt-64 pb-12 px-12 lg:px-24 mx-auto text-center h-screen">
				<div className="mx-auto">
					<h1 className="text-6xl text-kinzica-blue lg:text-8xl">Your music, our craft</h1>
					<p className="mt-6 text-gray-300 text-2xl lg:text-4xl font-mont font-thin">
						Producing high quality audio for your brand
					</p>
				</div>

				<div className="mx-auto my-32 flex lg:flex-row flex-col justify-center items-center 
				">
					<a href="/services" className='lg:w-[20%] md:w-[25%] w-full'>
						<button className='bg-kinzica-pink w-full font-mont font-light
						lg:text-2xl text-xl py-3 px-8 hover:opacity-95 
						hover:text-white rounded'>Services</button>
					</a>
					<a href="/contact" className='lg:w-[20%] md:w-[25%] w-full'>
						<button className='bg-kinzica-blue w-full font-mont font-light
						lg:text-2xl text-xl py-3 px-8 hover:opacity-95 
						hover:text-white rounded'>Contact us</button>
					</a>
				</div>


				<div role="button" onClick={() => scrollTo('#some-works')} className="flex-col justify-center mt-24">
					<p className="cursor-pointer text-center w-full mx-auto my-4 text-white hover:text-kinzica-pink text-2xl">Latest works</p>
					<div className="cursor-pointer hover:animate-bounce flex items-center justify-center w-10 h-10 mx-auto text-white duration-300 transform border border-gray-400 rounded-full hover:text-kinzica-pink hover:border-kinzica-pink hover:shadow hover:scale-110">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="12"
							height="12"
							viewBox="0 0 12 12"
							fill="currentColor"
						>
							<path d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z" />
						</svg>
					</div>
				</div>
			</div>
		</section>

	)
}
