/* This example requires Tailwind CSS v2.0+ */

import React from "react"

import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card } from "./Card"

export const LandingGrid = ({ id, projects }) => {
  console.log(projects)

  return (
    <section
      id={id}
      className="p-8 py-12 lg:px-16 mx-auto w-full bg-kinzica-dark"
    >
      <div className="text-center flex flex-col">
        <div className="grid gap-2 sm:grid-cols-2 lg:grid-cols-3">
          {projects.map(p => (
            <Link
              to={"/works/" + p.frontmatter.tag + "/" + p.frontmatter.slug}
              key={p.id}
            >
              {/* <div className="text-center bg-gray-900">
								<GatsbyImage image={getImage(p.frontmatter.thumb.childImageSharp.gatsbyImageData)} alt="Banner"/>
								<h1 className="text-2xl text-white">{p.frontmatter.title}</h1>
								<p className="font-semibold text-gray-200">
									{p.frontmatter.stack}
								</p> 
							</div> */}
              <Card
                firstImage={getImage(
                  p.frontmatter.thumb.childImageSharp.gatsbyImageData
                )}
                title={p.frontmatter.title}
              />
            </Link>
          ))}
        </div>
        <Link to={"/works"}>
          <p className="text-2xl lg:text-end text-center my-8 text-white hover:text-kinzica-pink mx-auto">
            See all works →
          </p>
        </Link>
      </div>
    </section>
  )
}
